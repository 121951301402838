<template>
    <div>
        <SignupInput/>
    </div>
</template>

<script>
import SignupInput from '../components/SignupInput.vue'
    export default {
        name : 'Register',
        components : {
            SignupInput
        }
    }
</script>

<style lang="scss" scoped>

</style>